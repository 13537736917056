.otp-group {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    margin: 4rem 2.5rem;
}

.otp-group-6 {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: .5rem;
    margin: 1rem 0.5rem;
}
.otp-input {
    width: 4rem;
    height: 4rem;
    border: 1px solid #ccc;
    border-radius: 1rem;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
}

@media (min-width: 768px) {
    .otp-input-6 {
        width: 4rem;
        height: 4rem;
        border-radius: 1rem;
    }
}

@media (min-width: 1024px) {
    .otp-input-6 {
        width: 4rem;
        height: 4rem;
        border-radius: 1rem;
    }
}

@media (max-width: 768px) {
    .otp-input-6 {
        width: 3rem;
        height: 3rem;
        border-radius: .5rem;
    }

    .otp-input{
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 1rem;
    }
}

@media (max-width: 320px) {
    .otp-input-6 {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: .5rem;
    }

    .otp-input{
        width: 3rem;
        height: 3rem;
        border-radius: .5rem;
    }
    .otp-group {
        column-gap: 1rem;
        margin: 2rem 1.5rem;
    }
}
.otp-input-6 {
    border: 1px solid #ccc;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
}