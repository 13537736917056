@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* utils */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.grid {
    grid-auto-flow: row dense;
    grid-auto-rows: minmax(180px, auto);
}

.stretched-link {
    @apply absolute inset-0 z-10;
}

/* card */

.card .card-shadow {
    @apply transition duration-300 shadow-none h-full;
}

.card:hover .card-shadow {
    @apply shadow-lg;
}

/* overlay */

.overlay {
    @apply absolute;
}

.overlay * {
    @apply transition-all duration-200;
}

.overlay.hoverable > * {
    @apply opacity-0;
}

.Help {
    @apply bg-gray-200 font-face-poppinsregular px-4 pb-10 mt-4 text-product_name leading-6;
}

.Help > a {
    @apply text-blue-link underline font-face-poppinsbold text-product_name leading-6;
}

.Help > p > a {
    @apply text-blue-link underline font-face-poppinsbold text-product_name leading-6;
}

.Description {
    @apply bg-green-200 font-face-poppinsregular text-green-600 rounded-xl text-product_name leading-8;
}
.Description ul li {
    list-style-type: disc;
    margin-left: 16px;
}

.Description > a {
    @apply text-blue-link underline font-face-poppinsbold text-product_name leading-6;
}

.Description > p > a {
    @apply text-blue-link underline font-face-poppinsbold text-product_name leading-6;
}

.Description ol {
    list-style-type: decimal;
    margin-left: 16px;
}

.instruction strong {
    @apply font-face-poppinsbold;
}

.instruction ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1 em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 16px;
}

.instruction ol {
    display: block;
    list-style-type: decimal;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 16px;
}

.instruction p {
    margin-top: 6px;
    margin-bottom: 6px;
}

.instruction > p > a {
    @apply text-blue-link hover:underline font-face-poppinsbold leading-6;
}

.instruction h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.instruction h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.instruction h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.instruction h4 {
    display: block;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.instruction h5 {
    display: block;
    font-size: 0.83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.instruction h6 {
    display: block;
    font-size: 0.67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.instruction table {
    display: table;
    border-collapse: separate;
    border-spacing: 2px;
    border-color: gray;
}

.instruction td {
    display: table-cell;
    vertical-align: inherit;
}

.instruction th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
    text-align: center;
}

.instruction thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

.instruction tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}

.instruction u {
    text-decoration: underline;
}

.instruction em {
    font-style: italic;
}

.title-bar {
    background-color: #ffffff;
    height: 44px;
    width: 100%;
    display: flex;
}

.title {
    text-align: center;
    font-size: 36px;
    line-height: 180%;
    flex: 1 0 auto;
}

.overlay.hoverable:hover > * {
    @apply opacity-100;
}

/* .overlay .video {
  @apply absolute transform bg-blue-200 bg-no-repeat bg-center w-20 h-20 rounded-full items-center justify-center flex -translate-x-1/2 -translate-y-1/2;
  background-image: url("../images/content/icon-play.svg#icon-play");
  top: 50%;
  left: 50%;
} */

@media only screen and (max-width: 768px) {
    .hero .hero-image,
    .hero .hero-caption {
        min-height: 90vh;
    }
    .hero .hero-cta {
        @apply w-12 h-12;
        transform: translateX(-75px) translateY(-50%);
        background-size: 37.5%;
    }
}

/* breadcrumb */

ul.breadcrumb {
    @apply flex flex-row items-center text-lg;
}

ul.breadcrumb li:not(:last-child):after {
    content: '/';
    @apply px-4;
}

ul.breadcrumb li:not(:last-child) a {
    @apply font-light;
}

ul.breadcrumb li:last-child a {
    @apply font-semibold;
}

/* slider */

.slider {
    @apply flex my-4 flex-col;
}

.slider .thumbnail {
    @apply w-screen order-last -mx-2 overflow-auto flex pl-4;
}

.slider .thumbnail .item {
    @apply rounded bg-gray-100 relative rounded-lg mb-4 cursor-pointer;
    width: 106px;
    height: 106px;
}

.slider .thumbnail .item:after {
    content: '';
    @apply transition duration-200 absolute inset-0 bg-black bg-opacity-0 border-2 border-black border-opacity-0 rounded-lg;
}

.slider .thumbnail .item.selected:after {
    content: '';
    @apply bg-opacity-25 border-opacity-100;
}

.slider .preview {
    @apply mb-4 px-4;
    height: 320px;
}

@media only screen and (min-width: 768px) {
    .slider {
        @apply flex-row;
    }
    .slider .thumbnail {
        @apply w-auto order-first block overflow-visible pl-0;
    }
    .slider .preview {
        height: 600px;
        width: 560px;
    }
}

a.cart svg g circle.dot {
    @apply hidden;
}

a.cart.cart-filled svg g circle.dot {
    @apply block;
}

button[disabled] {
    @apply bg-gray-300 hover:bg-gray-300 text-gray-400 hover:text-gray-400 cursor-not-allowed;
}

@font-face {
    font-family: 'TelkomselBold';
    src: local('TelkomselBold'), url('../fonts/TelkomselBatikSans-Bold.otf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'TelkomselRegular';
    src: local('TelkomselRegular'), url('../fonts/TelkomselBatikSans-Regular.otf') format('truetype');
}

@font-face {
    font-family: 'PoppinsRegular';
    src: local('PoppinsRegular'), url('../fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'PoppinsBold';
    src: local('PoppinsBold'), url('../fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'PoppinsLight';
    src: local('PoppinsLight'), url('../fonts/Poppins-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'PoppinsSemiBold';
    src: local('PoppinsSemiBold'), url('../fonts/Poppins-SemiBold.ttf') format('truetype');
}

.font-face-tselbold {
    font-family: 'TelkomselBold', sans-serif;
}

.font-face-tselregular {
    font-family: 'TelkomselRegular', sans-serif;
}

.font-face-poppinsregular {
    font-family: 'PoppinsRegular', sans-serif;
}

.font-face-poppinsbold {
    font-family: 'PoppinsBold', sans-serif;
}

.font-face-poppinslight {
    font-family: 'PoppinsLight', sans-serif;
}

.font-face-poppinssemibold {
    font-family: 'PoppinsSemiBold', sans-serif;
}

.h-button_expand {
    height: 2px;
}

.lds_ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds_ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #173647;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

@media (max-width: 720px) {
    .lds_ellipsis div {
        width: 10px;
        height: 10px;
    }
}

.lds_ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds_ellipsis1 0.6s infinite;
}
.lds_ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds_ellipsis2 0.6s infinite;
}
.lds_ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds_ellipsis2 0.6s infinite;
}
.lds_ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds_ellipsis3 0.6s infinite;
}
@keyframes lds_ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds_ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds_ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
